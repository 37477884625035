.classified-container {
	height: 100%;
	width: 100%;

	overflow-x: hidden;
	overflow-y: hidden;
}

.classification-ribbon {
	z-index: 999;
	width: 100%;
	height: 30px;
	color: black;
	display: flex;
	align-items: center;
	margin: auto;
	font-weight: bolder;
}

.label {
    margin: auto;
}

.footer {
    position: fixed;
	bottom: 0px;
}

.child-container {
	height: calc(100% - 60px);
	overflow-y: auto;
}