#background-container {
    display: flex;
    align-items: center;
}

/* TODO This should be moved out to SCSS and reconfigured */
.MuiButton-label {
    color: black !important;
}

.MuiButton-fullWidth {
    background: #fec325 !important;
}