.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

html {
    /*overflow-y: scroll;
    scrollbar-color: rgba(105, 112, 125, 0.5) transparent;
    background-color: #f7f7f7;*/
}
body {
    overflow-x: hidden;
    background-color: #f7f7f7;
}
#root {
    margin-right: calc(-1 * (100vw - 100%));
}
.MuiButton-label {
    text-transform: none;
    color: #fff;
}
header.MuiAppBar-colorPrimary {
    background-color: #000;
    color: #fff;
}
.MuiToolbar-regular { height: 48px;}
.logoH {
width: 28px;
margin: 0 20px;
}
.amicBrand {
    font-weight: bold;
    letter-spacing: 0.1rem;
    margin-right: 4px;
}
.MuiToolbar-regular {
    min-height: 64px;
    /* media queries */
    @media (min-width: 600px) {
      min-height: 48px;
    }
}
.euiFieldSearch {
    background: #ffffff;
}
button.euiFacetButton.euiFacetButton--unSelected .euiNotificationBadge {
    background: #fec325;
    color: #000;
}
button.euiFacetButton.euiFacetButton--isSelected .euiNotificationBadge {
    background: #9B9B99;
    color: #000;
}
.euiPageHeaderSection button.euiButton {
    background: #fadd8e;
}
.euiPageHeaderSection button.euiButton .euiButton__text {
    font-weight: bold;
    color: #000;
    font-size: small;
}
.euiPageBody .euiPanel.euiPanel--plain {
    background-color: #e8e8e8;
}
.euiPageContentBody > .euiFlexGroup--directionRow {
    background-color: #fff;
    margin: 0 0 15px;
    padding: 0 0 40px 0;
}
h3.euiTitle--xxsmall {

    color: #bbbbbb;
    margin-top: 16px;
}
.euiFormControlLayout {
    margin-bottom: 16px;
}
.euiPageSideBar hr.euiHorizontalRule {
    display: none;
}
.IntegratedSearchAndNavLayout-root-3 .euiPage--paddingMedium {
    padding: 16px 16px 16px 0;
    background-color: #f7f7f7;
}
.euiPage .euiPageSideBar {
    min-width: 250px;
    margin-right: 0;
}
.euiPageSideBar > div:not(.euiFormControlLayout) {
    border-top: solid 1px #ccc;
    margin-right: unset;
}
.euiPageSideBar > div > div {
    margin: 0 16px;
}
.euiPageSideBar > div > h3.euiTitle {
    margin-left: 15px;
}
.euiButton:hover:not([class*="isDisabled"]):hover {
    transform: none;
    -webkit-transform: none;
}
.euiButton.euiButton--primary {
    background: #cdbeff;
}
button.euiButton--primary {
    border-color: unset;
    border: none;
}
#searchPage button.euiButton--primary {
    background: #fadd8e;
    color: #000;
}
#searchPage {
    /* overflow: auto; */
}
@media screen and (max-width: 889px) {
    #root .MuiBottomNavigation-root {
        height: unset;
    }
}
/* DatasetContainer */
/*.IntegratedSearchAndNavLayout-grow-13 .IntegratedSearchAndNavLayout-root-3 {
    padding: 0;
    background: #f7f7f7;
}*/
/* Moved to responsive section (bottom)
.IntegratedSearchAndNavLayout-grow-12 .IntegratedSearchAndNavLayout-root-2, .IntegratedSearchAndNavLayout-grow-13 .IntegratedSearchAndNavLayout-root-3 {
    padding: 20px 40px 60px;
    background: #f7f7f7;
}
*/

.dataBox {
    padding: 0;
    background: #ffffff;
}
#datasetTitle {
    padding: 20px 20px 10px 40px;
}
#datasetTitle button {
    box-shadow: 0 2px 2px -1px rgba(54, 97, 126, 0.3);
}
#datasetTitle > button > .MuiButton-label {
    color: #000000;
}
.dataBox .mapContainer {
    box-shadow: 2px 4px 8px 3px #aaa;
    outline: 1px solid #848484;
}
.dataBox .MuiTypography-colorTextSecondary, .dataBox .MuiTypography-body1 {
    color: rgb(162, 162, 162);
}
.dataBox .MuiTypography-body1 {
    margin: 1em 0 0 0;
}
#datasetWindow h2.MuiTypography-root.MuiTypography-h6 {
    margin-left: 20px;
}
#datasetLocation {
    background: #f7f7f7;
    padding: 0 36px;
}
.dataBox p.dataDesc {
    color: #000000;
    margin: 0 0 0 20px;
}
.MuiBottomNavigation-root.makeStyles-root-20, .MuiBottomNavigation-root.makeStyles-root-21, .MuiBottomNavigation-root.makeStyles-root-22 {
    background: #fec325;
    color: #000000;
    /* height: 40px; */
}
#feedbackTrigger {
    color: #ffffff;
    height: 40px;
    margin: 0 39px;
    white-space: nowrap;
}
#feedbackTrigger .MuiBottomNavigationAction-wrapper {
    display: table;
    width: unset;
    background: #000000;
    padding: 4px 14px;
    border-radius: 8px;
}
#datasetWindow .MuiPaper-elevation1 {
    box-shadow: none;
}
.MuiBottomNavigationAction-wrapper .MuiSvgIcon-root {
    vertical-align: middle;
    margin-right: 14px;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
    background: #fec325;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary .MuiButton-label {
    color: #000000;
}
.makeStyles-root-21 .MuiBottomNavigationAction-root {
    min-width: unset;
}
@media screen and (min-width: 1890px) {
    .colLeftMap {
        max-width: 30%;
    }
    .euiFlexGroup--directionRow {
        max-width: calc(100vw - 300px);
        flex-wrap: wrap;
    }
}
.euiFlexGroup--directionRow {
    flex-wrap: wrap;
}
.MuiAvitar-root {
    top: 10px;
}
.MuiTypography-h6 {
    font-size: 1.15rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.0075em;
}
.MuiChip-root {
    height: 25px;
}

button.datasetUpload {
    background: #fadd8e;
}
button.datasetUpload .MuiButton-label {
    color: #000000;
}

#dataInfo {
    width: 100%;
    flex: unset;
    margin-top: 1em;
}
#dataInfo .MuiTabPanel-root {
    border: 1px solid #d2cdcd;
    width: 100%;
    box-shadow: 2px 2px 4px 0px #b3a9a9;
}
.MuiGrid-spacing-xs-2 {
    width: 100%;
}
#datasetWindow.MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 12px 12px 0 12px;
}
#datasetLocation .pins {
    display: grid;
    grid-template-columns: 1fr 6fr;
}
.MuiAvatar-root {
    top: 8px;
}
#datasetWindow .MuiTab-root {
    min-width: 130px;
}
.MuiTab-root {
    -webkit-tap-highlight-color: transparent;
}
.MuiTab-root .tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
}
.MuiButtonBase-root {
    display: inline-block;
    border: 1px solid transparent important!;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}
.MuiButtonBase-root .Mui-selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
}
#datasetWindow .MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    color: #3b21f3;
    background: linear-gradient(0deg, rgb(162, 161, 161) 0%, rgb(117, 117, 117) 27%, rgb(23, 23, 23) 100%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
}
.MuiButtonBase-root .Mui-disabled {
    color: GrayText;
    cursor: default;
}
.MuiTab-root .Mui-tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
}
.MuiTab-root__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}
.MuiTab-root__tab-panel {
    display: none;
}
.MuiTab-root__tab-panel--selected {
    display: block;
}
#datasetWindow .MuiTabs-scroller {
    white-space: pre-wrap;
}
#datasetWindow .MuiTabs-flexContainer {
    flex: none;
    display: inline-block;
}
#datasetWindow .MuiButtonBase-root.MuiTab-root {
    display: inline-block;
    border: 1px solid #ececec;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: background-color 300ms linear;
    border-bottom: none;
    margin-bottom: -40px;
    background: #fff;
    padding-bottom: 50px;
}
#datasetWindow .MuiTab-root {
    /*background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 47%, rgb(234, 234, 234) 100%);*/
    border: 1px solid #ececec;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: background-color 300ms linear;
}
#datasetWindow .MuiTabs-indicator {
    height: unset;
}
#datasetWindow .Mui-selected .MuiTab-wrapper {
    color: #ffffff;
    font-weight: 700;
}

#datasetWindow .MuiBottomNavigation-root p {
    padding: 10px;
}

.avengers-tab-list {
    margin: 0;
    padding: 0;
}
.avengers-tab {
    display: inline-block;
    position: relative;
    list-style: none;
    padding: 6px 12px 0;
    cursor: pointer;
    border-radius: 5px 5px 0 0;
    margin-right: 2px;
}
.avengers-tab--selected {
    box-shadow: 2px -1px 2px 0px rgba(0, 0, 0, 0.5);
    background: #fff;
    color: black;
    z-index: 3;
}
.avengers-tab-panel {
    padding: 10px;
    display: none;
    z-index: 2;
    position: relative;
}
.avengers-tab-panel--selected {
    box-shadow: 2px -1px 2px 0px rgba(0, 0, 0, 0.5);
    display: block;
}

#dataInfo .MuiTabPanel-root {
    padding: 0;
}

/* Large screens */

@media screen and (min-width: 1201px) {
    .IntegratedSearchAndNavLayout-grow-12 .IntegratedSearchAndNavLayout-root-2,
    .IntegratedSearchAndNavLayout-grow-13 .IntegratedSearchAndNavLayout-root-3 {
        padding: 20px 40px 80px;
        background: #f7f7f7;
    }
    .MuiTabPanel-root {
        padding: 24px;
    }
    .MuiBottomNavigation-root.makeStyles-root-20 {
        position: fixed;
        bottom: 0;
        width: 100vw;
    }
}

/* Medium screens */

@media screen and (min-width: 801px) and (max-width: 800px) {
    .IntegratedSearchAndNavLayout-grow-12 .IntegratedSearchAndNavLayout-root-2,
    .IntegratedSearchAndNavLayout-grow-13 .IntegratedSearchAndNavLayout-root-3 {
        padding: 10px 20px 30px;
        background: #f7f7f7;
    }
    .MuiTabPanel-root {
        padding: 10px;
    }
    .MuiBottomNavigation-root.makeStyles-root-20 {
        position: fixed;
        bottom: 0;
        width: 100vw;
    }
}

/* Narrow screens */

@media screen and (min-width: 421px) and (max-width: 800px) {
    .IntegratedSearchAndNavLayout-grow-12 .IntegratedSearchAndNavLayout-root-2,
    .IntegratedSearchAndNavLayout-grow-13 .IntegratedSearchAndNavLayout-root-3 {
        padding: 10px 20px 80px;
    }
    .MuiBottomNavigation-root.makeStyles-root-20 {
        position: fixed;
        bottom: 0;
        width: 100vw;
    }
}

/* Mobile devices */

@media screen and (max-width: 420px) {
    .IntegratedSearchAndNavLayout-grow-12 .IntegratedSearchAndNavLayout-root-2,
    .IntegratedSearchAndNavLayout-grow-13 .IntegratedSearchAndNavLayout-root-3 {
        padding: 0 0 40px;
    }
    .MuiBottomNavigation-root.makeStyles-root-20 {
        position: unset;
    }
    .MuiBottomNavigation-root p.MuiTypography-body1 {
        font-size: 0.6rem;
    }
}
